<template>
  <div class="forget">
    <heard-top></heard-top>
    <div class="forget-one direction">
      <div class="row forget-step">
        <div class="position-relative el-forget-step-div">
          <img
            src="../../assets/forget/1.png"
            alt=""
            class="el-step-img"
            v-if="forgetStep == 1"
          />
          <img src="../../assets/forget/2.png" alt="" class="el-step-img" v-else />
          <div
            class="position-absolute top-0 left-0 flex-center"
            :class="forgetStep == 1 ? 'forgetStepClass' : ''"
          >
            <div class="flex-center el-step-text">1</div>
            <div class="">填写账号</div>
          </div>
        </div>
        <div class="position-relative el-forget-step-div">
          <img
            src="../../assets/forget/1.png"
            alt=""
            class="el-step-img"
            v-if="forgetStep == 2 || forgetStep == 2.5"
          />
          <img src="../../assets/forget/2.png" class="el-step-img" alt="" v-else />
          <div
            class="position-absolute top-0 left-0 flex-center"
            :class="forgetStep == 2 || forgetStep == 2.5 ? 'forgetStepClass' : ''"
          >
            <div class="flex-center el-step-text">2</div>
            <div class="">安全验证</div>
          </div>
        </div>
        <div class="position-relative el-forget-step-div">
          <img
            src="../../assets/forget/1.png"
            class="el-step-img"
            alt=""
            v-if="forgetStep == 3"
          />
          <img src="../../assets/forget/2.png" class="el-step-img" alt="" v-else />
          <div
            class="position-absolute top-0 left-0 flex-center"
            :class="forgetStep == 3 ? 'forgetStepClass' : ''"
          >
            <div class="flex-center el-step-text">3</div>
            <div class="">设置新密码</div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-one" v-if="forgetStep == 1">
      <div>请输入您要找回密码的注册手机号/邮箱</div>
      <div class="el-div-input">
        <el-input placeholder="请输入手机号码/工作邮箱" v-model="user_name"></el-input>
      </div>
      <div
        class="el-step-botton bg-blue flex-center pointer text-white el-div-botton"
        @click="setpClick(2)"
      >
        下一步
      </div>
      <div class="flex-center pointer text-blue" @click="navTo">
        <span>返回登录</span>
        <img src="../../assets/forget/3.png" alt="" />
      </div>
    </div>
    <div class="el-step-two" v-if="forgetStep == 2">
      <div>
        验证当前账号邦定的手机号码/邮箱：<span class="text-blue">{{ user_name }}</span>
      </div>
      <div>图形验证码</div>
      <div class="el-div-img-code space-between">
        <div class="el-code-input">
          <el-input placeholder="不区分大小写，点击图片刷新" v-model="verify"></el-input>
        </div>
        <img :src="imgCode" class="el-code-img pointer" alt="" @click="getCaptcha" />
      </div>
      <div>短信验证码</div>
      <div class="el-div-img-code space-between div-num-code">
        <div class="el-code-input">
          <el-input
            placeholder="请输入短信验证码"
            type="number"
            v-model="code"
          ></el-input>
        </div>
        <div class="text-blue flex-center pointer el-class-code" @click="sendCode">
          {{ second }}
        </div>
      </div>
      <div
        class="el-step-botton text-white bg-blue flex-center pointer"
        @click="setpClick(2.5)"
      >
        下一步
      </div>
      <div class="flex-center setp-jy-text">
        注：如果你无法正常获取短信验证码，建议通过 <span>邮箱找回密码</span>
      </div>
    </div>
    <div class="step-three" v-if="forgetStep == 2.5">
      <div>
        <span class="text-blue">{{ user_name }} </span>注册账号如下，点击按钮进行重置
      </div>
      <div class="el-div-input" v-if="entObj">
        <el-input :placeholder="entObj.ent_name" disabled></el-input>
      </div>
      <div
        class="el-step-botton bg-blue flex-center pointer text-white"
        @click="setpClick(3)"
      >
        重置
      </div>
    </div>
    <div class="step-four" v-if="forgetStep == 3">
      <div class="four-title">请输入你需要设置的新密码</div>
      <el-form
        label-position="top"
        label-width="80px"
        :model="formLabelAlign"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="新密码" prop="password">
          <el-input
            v-model="formLabelAlign.password"
            placeholder="请设置登录密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="repwd">
          <el-input
            v-model="formLabelAlign.repwd"
            placeholder="请确认密码"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        class="el-step-botton bg-blue flex-center pointer text-white el-div-botton"
        @click="submitClick"
      >
        确定
      </div>
    </div>
  </div>
</template>

<script>
import heardTop from "../../components/heard.vue";
import api from "../../api/user";
import toolApi from "../../api/tool";
export default {
  components: {
    heardTop,
  },

  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formLabelAlign.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.formLabelAlign.repwd !== "") {
          this.$refs.ruleForm.validateField("repwd");
        }
        callback();
      }
    };
    return {
      user_name: "",
      // 判断当前是处于第几步
      forgetStep: 1,
      codeImg: "",
      code: "",
      formLabelAlign: {
        password: "",
        repwd: "",
      },
      //   验证规则
      rules: {
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        repwd: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      //   验证码
      second: "发送验证码",
      imgCode: "",
      // 图形验证码
      verify: "",
      loading: null,
      entObj: null,
    };
  },

  created() {},

  mounted() {},
  methods: {
    // 找回用户信息
    checkCodeUser(index) {
      let query = {
        user_name: this.user_name,
        verify: this.verify,
        code: this.code,
      };
      api.checkCodeUser(query).then((res) => {
        if (res.code == 200) {
          this.forgetStep = index;
          this.entObj = res.data;
        }
      });
    },
    // 获取图形验证码
    getCaptcha() {
      this.imgCode =
        "https://ganfangzhi.com/" +
        "/api/common/getCaptcha" +
        "?" +
        new Date().getTime();
    },
    // 验证账号是否存在
    checkUser(index) {
      api.checkUser({ user_name: this.user_name }).then((res) => {
        if (res.code == 200) {
          this.getCaptcha();
          this.forgetStep = index;
        }
      });
    },

    // 修改密码
    submitClick() {
      if(this.formLabelAlign.password.trim()==''){
        this.$util.msg('请输入密码','error')
        return
      }else if(this.formLabelAlign.repwd.trim()==''){
        this.$util.msg('请输入重复密码','error')
        return
      }else if(this.formLabelAlign.repwd.trim()!==this.formLabelAlign.password.trim()){
        this.$util.msg('两次密码不一致','error')
        return
      }
      let query={
        user_id:this.entObj.user_id,
        password:this.formLabelAlign.password,
        repwd:this.formLabelAlign.repwd
      }
      api.resetPassword(query).then((res) => {
        if (res.code == 200) {
          this.$alert("密码修改成功", "提示", {
            confirmButtonText: "重新登录",
            callback: () => {
              this.navTo();
            },
          });
        }
      });
    },
    //   跳转到登录页或者注册页面
    navTo() {
      this.$router.push({ path: "login", query: { type: 1 } });
    },
    // 下一步操作
    setpClick(index) {
      if (index == 2) {
        if (this.user_name.trim() == "") {
          this.$util.msg("请输入手机号码或邮箱", "error");
          return;
        }
        this.checkUser(index);
      } else if (index == 2.5) {
        if (this.verify == "") {
          this.$util.msg("请输入图形验证码", "error");
          return;
        } else if (this.code == "") {
          this.$util.msg("请输入验证码", "error");
          return;
        }
        this.checkCodeUser(index);
      } else if (index == 3) {
        this.forgetStep = index;
      }
    },
    sendForgetCode() {
      let query = {
        user_name: this.user_name,
        verify: this.verify,
      };

      api.sendForgetCode(query).then((res) => {
        if (res.code == 200) {
          this.$util.msg(res.msg);
          this.codeCountdown();
        } else {
          this.getCaptcha();
        }
        this.loading.close();
      });
    },
    // 获取验证码
    sendCode() {
      if (this.showText == false) {
        this.$util.msg("请不要重复点击", "warning");
        return;
      }
      if (this.user_name == "") {
        this.$util.msg("请输入电话或邮箱", "error");
        return;
      } else if (this.verify.trim() == "") {
        this.$util.msg("请输入图形验证码", "error");
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在获取验证码",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        this.sendForgetCode();
      }, 1000);
    },
    // 倒计时
    // 验证码倒计时
    codeCountdown() {
      this.showText = false;
      let time = 60;
      if (time > 0) {
        let Timea = setInterval(() => {
          time = time - 1;
          this.second = "重新发送" + time + "s";
          if (time == 0) {
            clearInterval(Timea);
            this.showText = true;
            this.second = "发送验证码";
          }
        }, 1000);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.forget {
  width: 100%;
  .forget-one {
    margin-top: 1.8125rem;
    .forget-step {
      color: #999999;
      font-size: 1rem;
      .el-forget-step-div {
        width: 258px;
        height: 35px;
        .el-step-img {
          width: 100%;
          height: 100%;
        }
        > div {
          width: 100%;
          height: 100%;
          .el-step-text {
            width: 20px;
            height: 20px;
            background: #999999;
            border-radius: 50%;
            color: #ffffff;
            font-size: 16px;
            margin-right: 7px;
          }
        }
        .forgetStepClass {
          color: #ffffff;
          div:first-child {
            background: #ffffff;
            color: #126bf9;
          }
        }
      }
    }
  }
  .step-one {
    margin: 0 auto;
    margin-top: 6.4375rem;
    padding: 0 11.25rem 0 10rem;
    width: 48.75rem;
    > div:first-child {
      font-size: 1.125rem;
      color: #333333;
    }
    .el-div-input {
      height: 42px;
      margin-top: 22px;
      margin-bottom: 39px;
    }
    .el-div-botton {
      margin-bottom: 34px;
    }
    > div:last-child {
      font-size: 1rem;
      img {
        width: 1rem;
        height: 1rem;
        margin-left: 5px;
      }
    }
  }
  .el-step-two {
    padding: 0 46.25rem;
    margin-top: 2.6875rem;
    color: #333333;
    > div:first-child {
      font-size: 1.125rem;
      margin-bottom: 2.875rem;
    }
    > div:nth-child(2) {
      font-size: 0.875rem;
    }
    .el-div-img-code {
      margin-top: 13px;
      margin-bottom: 24px;
      .el-code-input {
        width: 320px;
        height: 42px;
        /deep/.el-input__inner {
          height: 42px;
          line-height: 42px;
        }
      }
      .el-code-img {
        width: 130px;
        height: 42px;
      }
      .el-class-code {
        width: 111px;
        height: 42px;
        background: #f1f6ff;
        border: 1px solid #126bf9;
        font-size: 14px;
      }
    }
    .div-num-code {
      margin-bottom: 2.375rem;
    }
    .setp-jy-text {
      color: #666666;
      font-size: 0.875rem;
      span {
        color: #333333;
      }
    }
  }
  .step-three {
    padding: 0 46.25rem;
    margin-top: 2.6875rem;
    > div:first-child {
      font-size: 1.125rem;
      color: #666666;
    }
    .el-div-input {
      height: 42px;
      margin-top: 46px;
      margin-bottom: 50px;
    }
  }
  .step-four {
    margin-top: 2.75rem;
    padding: 0 46.25rem;
    .four-title {
      color: #333333;
      font-size: 0.875rem;
      margin-bottom: 1.875rem;
    }
    /deep/.el-form-item {
      margin-bottom: 10px;
    }
    /deep/.el-form-item__label {
      line-height: 32px;
    }
    .el-div-botton {
      margin-top: 40px;
    }
  }
}
</style>
